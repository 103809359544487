<template>
  <div class="content">
    <div class="explain" v-if="content.explain">
      <div class="title">信息说明</div>
      <div class="info-div">{{ content.explain }}</div>
    </div>
    <div class="goal" v-if="content.goal">
      <div class="title">使用目的</div>
      <div class="info-div">{{ content.goal }}</div>
    </div>
    <div class="scene" v-if="content.scene && content.scene.length > 0">
      <div class="title">使用场景</div>

      <div class="scene-div info-div" v-for="(item, index) in content.scene" :key="index">
        <div>
          <div>场景描述</div>
          <div>{{ item.sceneDesc }}</div>
        </div>
        <div style="margin-top: 0.24rem">
          <div>收集情况</div>
          <div>{{ handleCollectInfo(item) }}</div>
        </div>
        <div style="margin-top: 0.24rem">
          <div>信息内容</div>
          <div
            v-if="item.infoContent == '查看详情'"
            style="color: blue"
            @click="viewDetail(item.infoContent, item.url)"
          >
            {{ item.infoContent }}
          </div>
          <div v-else>{{ item.uid ? getUserUid() : '无内容' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { content, kind } from './data/PersonalInfoCollection';
  import API from '@/api';

  export default {
    async asyncData({ store, $http }) {},
    data() {
      return {
        routerListId: this.$router.currentRoute.query.listId,
        routerKindId: this.$router.currentRoute.query.kindId,
        content: null,
        kind: kind,
        info: {},
      };
    },
    created() {
      this.content = content[this.routerListId][this.routerKindId];
    },
    mounted() {
      console.log(`mounted`);
      this.getUserInfo();
      document.title = this.kind[this.routerListId][this.routerKindId].name;
      // this.$sdk.appCall(this.$sdk.callMethod.SET_TITLE, this.kind[this.routerListId][this.routerKindId].name);
      // this.$sdk.register(this.$sdk.listenMethod.ON_SHOW, () => {
      //   this.$sdk.appCall(this.$sdk.callMethod.SET_TITLE, this.kind[this.routerListId][this.routerKindId].name);
      // });
    },
    watch: {
      info: {
        handler(old, newValue) {
        },
        deep: true,
        immediate: true,
      },
    },
    methods: {
      async getUserInfo() {
        let userInfo = null;
        try {
          userInfo = this.$sdk.appCall(this.$sdk.callMethod.GET_USER_INFO);
        } catch (err) {
          console.error(err);
        }

        let client;
        if (userInfo) {
          client = JSON.parse(userInfo);
        }

        let basicInfo = {
          request_source: 'h5',
          access_token: client['accessToken'],
          platform: client.platform || 'Android',
          platform_brand: client['platform-brand'] || '',
          client_v: client['client-v'],
          device_id: client.sa_device_id,
          utm_source: client['utm-source'],
          visitor_token: client.visitor_token || '',
          tourist_token: client.tourist_token || '',
          app_name: client['app-name'] || 'kuaiyin',
        };
        const res = await this.$http.TaskPost(API.USER_GET_COMPLIANCE, { ...basicInfo });
        this.info = res;
      },
      viewDetail(name, url) {
        if (name == '查看详情' && url) {
          const params = {
            url: url,
          };
          this.$sdk.appCall(this.$sdk.callMethod.OPEN_PAGE_WITH_JSON, JSON.stringify(params));
        }
      },
      handleCollectInfo(item) {
        let returnInfo = '';
        if (this.info.user) {
          if (item.needReq) {
            returnInfo = item.reqParams
              ? '已收集' + (eval('this.info.' + item.reqParams) ? eval('this.info.' + item.reqParams) : 0) + '条'
              : '已收集';
          } else {
            returnInfo = '未收集';
          }
        } else {
          returnInfo = '未收集';
        }
        return returnInfo;
      },

      getUserUid() {
        let returnUid = '';
        if (this.info.user && this.info.user.uid) {
          let user = this.info.user;
          returnUid = user.uid.charAt(0) + '*****' + user.uid.charAt(user.uid.length - 1);
        } else {
          returnUid = '';
        }
        return returnUid;
      },
    },
  };
</script>
<style lang="less" scoped>
  .content > div {
    margin: 0.28rem 0.3rem;
  }

  .title {
    font-size: 0.32rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 0.44rem;
  }

  .info-div {
    background: #f5f5f5;
    border-radius: 0.2rem;
    padding: 0.2rem 0.24rem;
    margin-top: 0.16rem;

    font-size: 0.28rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 0.44rem;
  }

  .scene-div {
    & > div {
      display: flex;
      justify-content: space-between;
      font-size: 0.28rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;

      & > div:first-child {
        width: 3rem;
        color: #333333;
      }

      & > div:last-child {
        color: #656565;
      }
    }
  }
</style>
