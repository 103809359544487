export const List = [
  {
    name: '用户基本信息',
  },
  {
    name: '账号信息',
  },
  {
    name: '用户使用信息',
  },
  {
    name: '设备信息',
  },
];

export const kind = [
  [{ name: '头像' }, { name: '昵称' }, { name: '手机号码' }],
  [{ name: '用户注册账号' }, { name: '第三方平台账号' }],
  [
    { name: '播放记录' },
    { name: '下载记录' },
    { name: '分享记录' },
    { name: '歌曲合成记录' },
    { name: '歌手生成记录' },
    { name: '订单记录' },
    { name: '支付记录' },
  ],
  [{ name: '设备参数及系统信息' }],
];

export const content = [
  [
    {
      explain: '',
      goal: '完善账户信息',
      scene: [{ sceneDesc: '设置/更改头像', collectInfo: '已收集x次/未收集', infoContent: '无内容' }],
    },
    {
      explain: '',
      goal: '完善账户信息',
      scene: [{ sceneDesc: '用户填写个人资料时', collectInfo: '已收集x次/未收集', infoContent: '无内容' }],
    },
    {
      explain: '',
      goal: '账号注册/登录；实名认证；合成内容；用户调研；安全保障；客户服务',
      scene: [{ sceneDesc: '用户注册/登录时', collectInfo: '已收集x次/未收集', infoContent: '136******49' }],
    },
  ],
  [
    {
      explain: 'uid',
      goal: '账号注册/登录；安全保障',
      scene: [
        { sceneDesc: '用户注册/登录时', collectInfo: '未收集', infoContent: 'o********w', needReq: false, uid: true },
      ],
    },
    {
      explain: '包括openid/unionid等第三方平台标识符',
      goal: '账号注册/登录；安全保障',
      scene: [{ sceneDesc: '用户注册/登录时', collectInfo: '已收集x次/未收集', infoContent: 'o********w' }],
    },
  ],
  [
    {
      explain: '',
      goal: '安全保障；提供一致化、连续性的使用体验',
      scene: [
        {
          sceneDesc: '用户播放音视频内容时',
          collectInfo: '已收集x次/未收集',
          infoContent: '无内容',
          url: `kuaiyin://personal`,
          needReq: false,
        },
      ],
    },
    {
      explain: '',
      goal: '安全保障；提供一致化、连续性的使用体验',
      scene: [
        {
          sceneDesc: '用户下载时',
          collectInfo: '已收集x次/未收集',
          infoContent: '无内容',
          url: 'kuaiyin://down',
          needReq: false,
          reqParams: 'user.downloads',
        },
      ],
    },
    {
      explain: '',
      goal: '安全保障；提供一致化、连续性的使用体验',
      scene: [
        {
          sceneDesc: '用户分享内容时',
          collectInfo: '已收集x次/未收集',
          infoContent: '无内容',
          url: 'kuaiyin://down',
          needReq: false,
          reqParams: 'user.downloads',
        },
      ],
    },
    {
      explain: '用户输入的主题词、选择的曲风、选择的演唱歌手、合成的歌曲信息记录',
      goal: '内容/音色合成；展示合成结果；安全保障；客户服务；提供一致化、连续性的使用体验',
      scene: [
        {
          sceneDesc: '用户合成歌曲时',
          collectInfo: '已收集x次/未收集',
          infoContent: '无内容',
          url: `kuaiyin://personal`,
          needReq: false,
          reqParams: 'user.works',
        },
      ],
    },
    {
      explain: '录入音频记录、歌手生成记录',
      goal: '音色合成；展示合成结果安全保障；客户服务；提供一致化、连续性的使用体验',
      scene: [
        {
          sceneDesc: '用户生成歌手音色时',
          collectInfo: '已收集x次/未收集',
          infoContent: '无内容',
          url: `kuaiyin://personal`,
          needReq: false,
          reqParams: 'user.works',
        },
      ],
    },
    {
      explain: '',
      goal: '展示订单；下单与订单管理；安全保障',
      scene: [
        {
          sceneDesc: '用户下单时',
          collectInfo: '已收集x次/未收集',
          infoContent: '无内容',
          url: `kuaiyin://personal`,
          needReq: false,
        },
      ],
    },
    {
      explain: '',
      goal: '展示交易记录；完成支付；安全保障',
      scene: [{ sceneDesc: '用户支付时', collectInfo: '已收集x次/未收集', infoContent: '无内容' }],
    },
  ],
  [
    {
      explain: '设备类型、设备型号、设备品牌、分辨率，操作系统',
      goal: '提供音视频浏览、播放服务；安全保障',
      scene: [{ sceneDesc: '用户进入小程序时', collectInfo: '已收集x次/未收集', infoContent: '无内容' }],
    },
  ],
];
